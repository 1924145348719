@tailwind base;
@tailwind components;
@tailwind utilities;

#styled-scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;

}
#styled-scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
#styled-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #dee3ed;
  /*outline: 1px solid slategrey;*/
}